const toCookie = ['TOKEN']
const Cache = {
	//设置缓存(expire为缓存时效)
	set(key, value, expire) {
		if (toCookie.includes(key)) {
			const domain = location.hostname.split('.')
			domain.shift()
			let cookie = `${key}=${value}; Domain=${domain.join('.')}; Path=/`
			if (expire) cookie += `; Max-Age=${expire}`
			document.cookie = cookie
		}
		let data = {
			expire: expire ? (this.time() + expire) : "",
			value
		}
		if (typeof data === 'object')
			data = JSON.stringify(data);
		try {
			uni.setStorageSync(key, data)
		} catch (e) {
			return false;
		}
		return true
	},
	get(key) {
		let cookieValue, localStorageValue
		if (toCookie.includes(key)) {
			const cookies = document.cookie ? Object.fromEntries(document.cookie.split('; ').map(i => i.split('='))) : {}
			const cookieValue = cookies[key]
		}
		try {
			let data = uni.getStorageSync(key)
			const {value, expire} = JSON.parse(data)
			if(expire && expire < this.time()) {
				uni.removeStorageSync(key)
				localStorageValue = false
			}else {
				localStorageValue = value
			}
		} catch (e) {
			localStorageValue = false
		}
		return cookieValue || localStorageValue || false
	},
	//获取当前时间
	time() {
		return Math.round(new Date() / 1000);
	},
	remove(key) {
		if (toCookie.includes(key)) {
			document.cookie = `${key}=; max-age=0`
		}
		if(key) uni.removeStorageSync(key)
	}
}

export default Cache;
