
import Vue from 'vue'

const locales = {keys(){return []}}
global['____5B0C040____'] = true;
delete global['____5B0C040____'];
global.__uniConfig = {"globalStyle":{"navigationBarTextStyle":"black","navigationBarTitleText":"LikeShop","navigationBarBackgroundColor":"#FFFFFF","backgroundColor":"#F8F8F8","navigationStyle":"custom"},"easycom":{"autoscan":true,"custom":{"^u-(.*)":"@/components/uview-ui/components/u-$1/u-$1.vue","^unicloud-db$":"@dcloudio/uni-cli-shared/components/unicloud-db.vue","^uniad$":"@dcloudio/uni-cli-shared/components/uniad.vue","^ad-rewarded-video$":"@dcloudio/uni-cli-shared/components/ad-rewarded-video.vue","^ad-fullscreen-video$":"@dcloudio/uni-cli-shared/components/ad-fullscreen-video.vue","^ad-interstitial$":"@dcloudio/uni-cli-shared/components/ad-interstitial.vue","^ad-interactive$":"@dcloudio/uni-cli-shared/components/ad-interactive.vue","^page-meta$":"@dcloudio/uni-cli-shared/components/page-meta.vue","^navigation-bar$":"@dcloudio/uni-cli-shared/components/navigation-bar.vue","^uni-match-media$":"@dcloudio/uni-cli-shared/components/uni-match-media.vue"}}};
global.__uniConfig.compilerVersion = '4.08';
global.__uniConfig.darkmode = false;
global.__uniConfig.themeConfig = {};
global.__uniConfig.uniPlatform = 'h5';
global.__uniConfig.appId = '__UNI__5B0C040';
global.__uniConfig.appName = '米米馆';
global.__uniConfig.appVersion = '2.4';
global.__uniConfig.appVersionCode = '100';
global.__uniConfig.router = {"mode":"history","base":"/mobile/"};
global.__uniConfig.publicPath = "/mobile/";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":60000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {};
global.__uniConfig.qqMapKey = undefined;
global.__uniConfig.googleMapKey = undefined;
global.__uniConfig.aMapKey = undefined;
global.__uniConfig.aMapSecurityJsCode = undefined;
global.__uniConfig.aMapServiceHost = undefined;
global.__uniConfig.locale = "";
global.__uniConfig.fallbackLocale = undefined;
global.__uniConfig.locales = locales.keys().reduce((res,key)=>{const locale=key.replace(/\.\/(uni-app.)?(.*).json/,'$2');const messages = locales(key);Object.assign(res[locale]||(res[locale]={}),messages.common||messages);return res},{});
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-user-user', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/user/user"+'.vue')), 'pages-user-user'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-address_edit-address_edit', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/address_edit/address_edit"+'.vue')), 'pages-address_edit-address_edit'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user_address-user_address', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/user_address/user_address"+'.vue')), 'pages-user_address-user_address'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-news_list-news_list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/news_list/news_list"+'.vue')), 'pages-news_list-news_list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-news_details-news_details', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/news_details/news_details"+'.vue')), 'pages-news_details-news_details'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-3rd-party-login', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/user/3rd-party-login"+'.vue')), 'pages-user-3rd-party-login'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-3rd-party-pay', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/user/3rd-party-pay"+'.vue')), 'pages-user-3rd-party-pay'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-cv', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/user/cv"+'.vue')), 'pages-user-cv'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-verify', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/user/verify"+'.vue')), 'pages-user-verify'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-login', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/login/login"+'.vue')), 'pages-login-login'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-forget_pwd-forget_pwd', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/forget_pwd/forget_pwd"+'.vue')), 'pages-forget_pwd-forget_pwd'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-register-register', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/register/register"+'.vue')), 'pages-register-register'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-webview-webview', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/webview/webview"+'.vue')), 'pages-webview-webview'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-task-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/task/list"+'.vue')), 'pages-task-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-task-task', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/task/task"+'.vue')), 'pages-task-task'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_bill-user_bill', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/user_bill/user_bill"+'.vue')), 'pages-bundle-user_bill-user_bill'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-server_explan-server_explan', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/server_explan/server_explan"+'.vue')), 'pages-bundle-server_explan-server_explan'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-message_center-message_center', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/message_center/message_center"+'.vue')), 'pages-bundle-message_center-message_center'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-notice-notice', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/notice/notice"+'.vue')), 'pages-bundle-notice-notice'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_profile-user_profile', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/user_profile/user_profile"+'.vue')), 'pages-bundle-user_profile-user_profile'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-contact_offical-contact_offical', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/contact_offical/contact_offical"+'.vue')), 'pages-bundle-contact_offical-contact_offical'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-recharge_code-recharge_code', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/recharge_code/recharge_code"+'.vue')), 'pages-bundle-recharge_code-recharge_code'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-balance_transfer-balance_transfer', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/balance_transfer/balance_transfer"+'.vue')), 'pages-bundle-balance_transfer-balance_transfer'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-transfer_record-transfer_record', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/transfer_record/transfer_record"+'.vue')), 'pages-bundle-transfer_record-transfer_record'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-set_pay_pwd-set_pay_pwd', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/set_pay_pwd/set_pay_pwd"+'.vue')), 'pages-bundle-set_pay_pwd-set_pay_pwd'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-forget_pay_pwd-forget_pay_pwd', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/mmg-uniapp/src/pages/bundle/forget_pay_pwd/forget_pay_pwd"+'.vue')), 'pages-bundle-forget_pay_pwd-forget_pay_pwd'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/user/user',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,

          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"米米馆","navigationStyle":"custom","enablePullDownRefresh":true})
      },
      [
        createElement('pages-user-user', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-user-user',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/user',
isQuit:true,
isEntry:true,
  windowTop:0
}
},
{
path: '/pages/address_edit/address_edit',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"收货地址"})
      },
      [
        createElement('pages-address_edit-address_edit', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-address_edit-address_edit',
  isNVue:false,maxWidth:0,
  pagePath:'pages/address_edit/address_edit',
  windowTop:0
}
},
{
path: '/pages/user_address/user_address',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"收货地址"})
      },
      [
        createElement('pages-user_address-user_address', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user_address-user_address',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user_address/user_address',
  windowTop:0
}
},
{
path: '/pages/news_list/news_list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商城资讯"})
      },
      [
        createElement('pages-news_list-news_list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-news_list-news_list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/news_list/news_list',
  windowTop:0
}
},
{
path: '/pages/news_details/news_details',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"资讯详情"})
      },
      [
        createElement('pages-news_details-news_details', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-news_details-news_details',
  isNVue:false,maxWidth:0,
  pagePath:'pages/news_details/news_details',
  windowTop:0
}
},
{
path: '/pages/user/3rd-party-login',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"第三方登录"})
      },
      [
        createElement('pages-user-3rd-party-login', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-3rd-party-login',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/3rd-party-login',
  windowTop:0
}
},
{
path: '/pages/user/3rd-party-pay',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"第三方支付"})
      },
      [
        createElement('pages-user-3rd-party-pay', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-3rd-party-pay',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/3rd-party-pay',
  windowTop:0
}
},
{
path: '/pages/user/cv',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"CV中心","enablePullDownRefresh":true})
      },
      [
        createElement('pages-user-cv', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-cv',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/cv',
  windowTop:0
}
},
{
path: '/pages/user/verify',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"实名认证","enablePullDownRefresh":true})
      },
      [
        createElement('pages-user-verify', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-verify',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/verify',
  windowTop:0
}
},
{
path: '/pages/login/login',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"登录","enablePullDownRefresh":false})
      },
      [
        createElement('pages-login-login', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-login',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/login',
  windowTop:0
}
},
{
path: '/pages/forget_pwd/forget_pwd',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"忘记密码","enablePullDownRefresh":false})
      },
      [
        createElement('pages-forget_pwd-forget_pwd', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-forget_pwd-forget_pwd',
  isNVue:false,maxWidth:0,
  pagePath:'pages/forget_pwd/forget_pwd',
  windowTop:0
}
},
{
path: '/pages/register/register',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"注册账号"})
      },
      [
        createElement('pages-register-register', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-register-register',
  isNVue:false,maxWidth:0,
  pagePath:'pages/register/register',
  windowTop:0
}
},
{
path: '/pages/webview/webview',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-webview-webview', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-webview-webview',
  isNVue:false,maxWidth:0,
  pagePath:'pages/webview/webview',
  windowTop:0
}
},
{
path: '/pages/task/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"任务列表","enablePullDownRefresh":true})
      },
      [
        createElement('pages-task-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-task-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/task/list',
  windowTop:0
}
},
{
path: '/pages/task/task',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"任务详情"})
      },
      [
        createElement('pages-task-task', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-task-task',
  isNVue:false,maxWidth:0,
  pagePath:'pages/task/task',
  windowTop:0
}
},
{
path: '/pages/bundle/user_bill/user_bill',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"记录明细"})
      },
      [
        createElement('pages-bundle-user_bill-user_bill', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_bill-user_bill',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_bill/user_bill',
  windowTop:0
}
},
{
path: '/pages/bundle/server_explan/server_explan',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{})
      },
      [
        createElement('pages-bundle-server_explan-server_explan', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-server_explan-server_explan',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/server_explan/server_explan',
  windowTop:0
}
},
{
path: '/pages/bundle/message_center/message_center',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"消息中心"})
      },
      [
        createElement('pages-bundle-message_center-message_center', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-message_center-message_center',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/message_center/message_center',
  windowTop:0
}
},
{
path: '/pages/bundle/notice/notice',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"消息通知"})
      },
      [
        createElement('pages-bundle-notice-notice', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-notice-notice',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/notice/notice',
  windowTop:0
}
},
{
path: '/pages/bundle/user_profile/user_profile',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"个人设置"})
      },
      [
        createElement('pages-bundle-user_profile-user_profile', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_profile-user_profile',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_profile/user_profile',
  windowTop:0
}
},
{
path: '/pages/bundle/contact_offical/contact_offical',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"联系客服"})
      },
      [
        createElement('pages-bundle-contact_offical-contact_offical', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-contact_offical-contact_offical',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/contact_offical/contact_offical',
  windowTop:0
}
},
{
path: '/pages/bundle/recharge_code/recharge_code',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"充值记录","enablePullDownRefresh":false})
      },
      [
        createElement('pages-bundle-recharge_code-recharge_code', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-recharge_code-recharge_code',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/recharge_code/recharge_code',
  windowTop:0
}
},
{
path: '/pages/bundle/balance_transfer/balance_transfer',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"余额转账"})
      },
      [
        createElement('pages-bundle-balance_transfer-balance_transfer', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-balance_transfer-balance_transfer',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/balance_transfer/balance_transfer',
  windowTop:0
}
},
{
path: '/pages/bundle/transfer_record/transfer_record',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"转账记录"})
      },
      [
        createElement('pages-bundle-transfer_record-transfer_record', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-transfer_record-transfer_record',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/transfer_record/transfer_record',
  windowTop:0
}
},
{
path: '/pages/bundle/set_pay_pwd/set_pay_pwd',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"设置支付密码"})
      },
      [
        createElement('pages-bundle-set_pay_pwd-set_pay_pwd', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-set_pay_pwd-set_pay_pwd',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/set_pay_pwd/set_pay_pwd',
  windowTop:0
}
},
{
path: '/pages/bundle/forget_pay_pwd/forget_pay_pwd',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"忘记支付密码"})
      },
      [
        createElement('pages-bundle-forget_pay_pwd-forget_pay_pwd', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-forget_pay_pwd-forget_pay_pwd',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/forget_pay_pwd/forget_pay_pwd',
  windowTop:0
}
},
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
