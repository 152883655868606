/** S 是否H5端 **/

const IS_H5 = true





/** E 是否H5端 **/

/** S API BaseURL **/
const baseURLMap = {
	// 开发环境 
	development: location.origin + '/api/',
	// 生产环境
	production: location.origin + '/api/',
}

const baseURL = baseURLMap[process.env.NODE_ENV]


/** E API BaseURL **/


module.exports = {
    HEADER: {
        'content-type': 'application/json'
    },
	baseURL,					// API Base URL
}
