// 此版本发布于2020-12-17
let version = '1.8.3';

export default {
	v: version,
	version: version,
	// 主题名称
	type: [
		'primary',
		'success',
		'info',
		'error',
		'warning'
	]
}