import 'uni-pages';import 'uni-h5';import Vue from 'vue'
import App from './App'
import store from './store'
import {toast} from './utils/tools'
import Cache from './utils/cache'
import minxinsApp from '@/mixins/app'
import uView from "@/components/uview-ui"
import { isWebview } from "@dvlden/is-webview"

Vue.prototype.$toast = toast
Vue.prototype.$Cache = Cache
Vue.config.productionTip = false


Vue.use(uView);
App.mpType = 'app'
Vue.mixin(minxinsApp);
const app = new Vue({
    ...App,
	store
})
app.$mount()
if (!('permissions' in navigator)) {
    navigator.clipboard.writeText = async 文本 => {
        const 节点 = document.createElement('input')
        节点.value = 文本
        document.body.append(节点)
        节点.select()
        const 结果 = document.execCommand('copy')
        节点.remove()
        if (!结果) throw new Error('复制失败')
    }
}